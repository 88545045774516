<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <!-- <div class="">
      <b-button
        variant="outline-secondary"
        class="ml-2 mt-5"
        @click="logout"
      >
        ログアウト
      </b-button>
    </div> -->
  </ul>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
// import { BButton } from 'bootstrap-vue'
import firebase from 'firebase/app'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    // BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      this.$store.dispatch('userBasic/resetAll')

      // Reset ability
      this.$ability.update(initialAbility)

      await firebase.auth().signOut()
        .then(() => {
          // ログイン画面に遷移
          this.$router.push({ name: 'login' })
        })
        .catch(e => {
          window.console.log(e)
        })
      // // Redirect to login page
      // this.$router.push({ name: 'login' })
      // this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
