export default [
  // {
  //   title: '求人情報一覧',
  //   icon: '',
  //   route: 'jobslist',
  // },
  // {
  //   title: '応募履歴',
  //   icon: '',
  //   route: 'applylist',
  // },
  {
    title: 'ダッシュボード',
    route: 'dashboad',
    icon: 'HomeIcon',
  },
  {
    title: 'プロフィール',
    route: 'profile',
    icon: 'FileTextIcon',
  },
  {
    title: 'アンケート',
    route: 'hearing',
    icon: 'EditIcon',
  },
  {
    title: 'プライバシーポリシー',
    icon: 'BookIcon',
    route: 'app-privacy',
  },
  {
    title: '利用規約',
    icon: 'BookOpenIcon',
    route: 'app-term',
  },
]
