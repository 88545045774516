export default [
  {
    title: 'アカウント一覧',
    route: 'admin-account-list',
    icon: '',
  },
  {
    title: '求職者一覧',
    route: 'admin-user-list',
    icon: '',
  },
]
